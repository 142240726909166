import { Article } from '@/Article';
import { CommonHero } from '@/CommonHero';
import styled from '@emotion/styled';
import { Fragment } from 'react';

const TheArticle = styled(Article)`
    > h5 {
        margin: 32px 0;

        :first-of-type {
            margin-top: 0;
        }
    }

    @media (min-width: 1280px) {
        > h5 {
            margin: 64px 0;

            :first-of-type {
                margin-top: 0;
            }
        }
    }
`;

const PrivacyPolicy = () => {
    return (
        <Fragment>
            <CommonHero />
            <TheArticle>
                <h5>Effective Date: April 18, 2016</h5>
                <p>
                    Beauty Religion (“we”, “our”) respect your privacy and are committed to using
                    responsible practices in the collection, maintenance and use of your personal
                    information. This privacy policy explains our on-line information practices and
                    applies to your use of www.beautyreligion.com (the “Website”) and other services
                    offered through the beautyreligion.com domain (the “Privacy Policy”).
                </p>

                <h5>Personally Identifiable Information</h5>
                <p>
                    Throughout our Website, you may voluntarily submit information that personally
                    identifies you (“Personally Identifiable Information”). In general, you can
                    visit our Website without telling us who you are or sharing any Personally
                    Identifiable Information about yourself except as follows. We only collect
                    Personally Identifiable Information about you if you choose to share it with us.
                    For example, you may choose to provide Personally Identifiable Information by
                    contacting us via Email or the contact form on our website. Personally
                    Identifiable Information may include, but is not limited to, your name, Email
                    address, physical address, phone number, and messages that you can send to us.
                </p>

                <h5>Anonymous Usage Data</h5>
                <p>
                    Like many other websites, ours may collect anonymous usage data (“Non-Personally
                    Identifiable Information”) about your activity at this Website. We may store
                    information about the type of browser you are using, your Internet Protocol
                    (“IP”) address, the time, date, and length of your visit; and the referring
                    site. This information is collected via computer code sent to your computer
                    (commonly referred to as a “cookie”). The cookie will not expire, unless you
                    manually delete it or set your browser to reject it. If you do not accept the
                    cookie, however, you may have difficulty navigating our full Website.
                </p>

                <h5>Use of Information</h5>
                <p>
                    We use Non-Personally Identifiable Information to manage this Website. We may
                    analyze aggregated anonymous information on the pages visited by our users to
                    make our Website more accessible and useful. Further, we may share this data
                    with third parties, usually to provide services for Beauty Religion associated
                    with the maintenance of the Website.
                </p>

                <p>
                    Except as outlined in this Privacy Policy or on the Website, Beauty Religion
                    will not sell or share your Personally Identifiable Information to others
                    without your consent. If you choose to submit Personally Identifiable
                    Information to the Website, we will use the information for the purpose you
                    requested. In addition, we may share this information with third parties to
                    fulfill your request or to provide other services that may be of interest to
                    you. If you do not wish Beauty Religion to use your information in this manner,
                    then do not submit any Personally Identifiable Information to us. In addition,
                    Beauty Religion may disclose Personally Identifiable Information about you if we
                    believe in good faith that the law requires it (such as a subpoena, court order
                    or a law enforcement request), or if we reasonably believe it is necessary to
                    protect our legal interests. The information collected from you may be
                    transferred to a third party in the event of a sale, acquisition, merger or
                    bankruptcy involving us.
                </p>

                <h5>Links to Other Websites</h5>
                <p>
                    For your convenience, our Website may contain links to other websites whose
                    information and privacy practices are probably different than ours. If you
                    choose to use these links, you will leave our Website, and will then be subject
                    to the privacy and information practices of those websites. We do not control
                    and are not responsible for any of their content or their privacy policies, if
                    any. We do not endorse or make any representations about them, or any
                    information, software or other products or materials found there, or any results
                    that may be obtained from using them. If you choose to access any of the other
                    websites listed on our Website, you should understand that you do so at your own
                    risk.
                </p>

                <h5>Security</h5>
                <p>
                    We employ commercially reasonable security measures to reduce the risk of
                    unauthorized access to your Personally Identifiable Information. However, we
                    cannot and do not guarantee that these measures will be sufficient to protect
                    against all efforts to gain unauthorized access to that information.
                </p>

                <h5>Children’s Privacy</h5>
                <p>
                    We are committed to complying with the Children’s Online Privacy Protection Act
                    (COPPA). Our Website is not directed to children, and we do not knowingly
                    collect Personally Identifiable Information from children. If we receive
                    Personally Identifiable Information that we discover was provided by a child, we
                    will promptly destroy such information. Schools and parents should supervise
                    their children’s online activities and consider the use of other means to
                    provide a child-friendly, online environment. If you would like to learn more
                    about COPPA, visit the Federal Trade Commission home page at http://www.ftc.gov.
                </p>

                <h5>California Privacy Rights</h5>
                <p>
                    Pursuant to California Civil Code Section 1798.83, California residents may
                    request certain information regarding our disclosures in the prior calendar
                    year, if any, of their Personally Identifiable Information to third parties for
                    their own direct marketing purposes. To make such a request, please Email us
                    with “California Privacy Rights Request” in the subject line. You must include
                    sufficient detail for us to locate your file; at a minimum, your name, email and
                    postal address.
                </p>

                <h5>California “Do Not Track” Disclosures</h5>
                <p>
                    We do not track your on-line activity across other websites, nor do we knowingly
                    permit any third-parties to conduct online tracking of you while you are
                    visiting our Website. Consequently, we do not respond to your browser’s Do Not
                    Track signal.
                </p>

                <h5>Application of United States Laws</h5>
                <p>
                    Our Website is based on computers located in the United States. Your Personally
                    Identifiable Information will be used and stored in the United States, where
                    data protection and privacy regulations may differ from the level of protection
                    as in other parts of the world.
                </p>

                <h5>How to contact us</h5>
                <p>
                    If you have any questions or concerns about Beauty Religion ’s Privacy Policy or
                    its implementation, you may contact us using the information provided on this
                    Website’s “contact us” section. If at any time you decide that we should no
                    longer hold any of your personal data, or wish to change the use to which any
                    such data can be put, please let us know by e-mailing us.
                </p>

                <h5>Consent and Revisions</h5>
                <p>
                    Beauty Religion may amend this Privacy Policy at any time without specific
                    notice to you. You can determine if this Privacy Policy has been revised since
                    your last visit by referring to the “Effective Date” at the top of this page.
                    Your use of our Website constitutes your acceptance of the terms of the Privacy
                    Policy as amended or revised by us, and you should review this Privacy Policy
                    each time upon visiting the Website to stay informed of its terms.
                </p>
            </TheArticle>
        </Fragment>
    );
};

export default PrivacyPolicy;
